
.expert-page {
    .scholar-wrap {
        padding: 10px 30px 0;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        .scholar {
            display: flex;
            margin-top: 21px;
            .scholar-img {
                margin-right: 30px;
                margin-bottom: 29px;
                img {
                    width: 200px;
                    height: 260px;
                    box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
                }
            }
            .scholar-info {
                flex: 1;
                h5 {
                    font-size: 30px;
                    line-height: 47px;
                    margin-bottom: 5px;
                }
                .university-scholar {
                    color: #666666;
                    font-size: 14px;
                    line-height: 14px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e5e5e5;
                    margin-bottom: 14px;
                }
                .content-scholar {
                    line-height: 28px;
                    font-size: 16px;
                    // text-overflow: -o-ellipsis-lastline;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 6;
                    // line-clamp: 6;
                    // -webkit-box-orient: vertical;
                }
            }
        }
        .scholar-chart {
            width: 1138px;
            height: 270px;
            border: 1px solid #e6e6e6;
            margin-bottom: 30px;
            margin-top: 10px;
            display: flex;
            position: relative;
            .scholar-chart-num {
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 90px;
                width: 210px;
                height: 180px;
                display: flex;
                flex-direction: column;
                p {
                    font-size: 26px;
                    color: #333;
                    margin-bottom: 20px;
                    text-align: center;
                    margin-top: 20px;
                }
                h5 {
                    font-size: 50px;
                    line-height: 50px;
                    color: #333;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .article-wrap {
        padding: 10px 30px 0;
        margin: 26px auto;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .book-wrap {
        padding: 10px 30px 0;
        margin-bottom: 26px;
        box-sizing: border-box;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    }
    .source-wrap {
        margin-bottom: 25px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        padding: 10px 30px 26px;
        box-sizing: border-box;
        .com-title {
            border-bottom: none;
            margin-bottom: 10px;
        }
        .source {
            li {
                width: 1138px;
                display: flex;
                align-items: center;
                padding: 16px 0 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-bottom: 1px dashed #e6e6e6;
                span {
                    background: #bd1a2d;
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    color: #fff;
                    font-size: 10px;
                    text-align: center;
                    border-radius: 4px;
                }
                p {
                    font-size: 18px;
                }
            }
        }
    }
    .relevant-expert-ins-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .relevant-expert-wrap {
            .relevant-expert {
                padding-top: 14px;
                display: flex;
                // justify-content: space-between;levant-ins

                .relevant-expert-img {
                    margin-right: 13px;
                    img {
                        width: 106px;
                        height: 142px;
                        cursor: pointer;
                        box-shadow: 0px 3px 6px 0px rgba(15, 11, 5, 0.29);
                    }
                }
                .relevant-expert-info {
                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 8px;
                        cursor: pointer;
                    }
                    p {
                        color: #666;
                        line-height: 28px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4; // 控制多行的行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                li {
                    width: 136px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    position: relative;
                    font-size: 18px;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    background: #ffac13;
                }
            }
        }
        .relevant-ins-wrap {
            .relevant-ins {
                padding-top: 14px;
                display: flex;
                // justify-content: space-between;
                .relevant-ins-img {
                    margin-right: 13px;
                    img {
                        width: 106px;
                        height: 142px;
                        cursor: pointer;
                        box-shadow: 0px 3px 6px 0px rgba(15, 11, 5, 0.29);
                    }
                }
                .relevant-ins-info {
                    h5 {
                        font-size: 18px;
                        line-height: 18px;
                        margin-bottom: 8px;
                        cursor: pointer;
                    }
                    p {
                        color: #666;
                        line-height: 28px;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4; // 控制多行的行数
                        -webkit-box-orient: vertical;
                    }
                }
            }
            ul {
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 182px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    position: relative;
                    font-size: 18px;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 12px;
                    background: #ffac13;
                }
            }
        }
        .com-relevant {
            width: 588px;
            height: 298px;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 20px;
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 8px;
                li {
                    margin-bottom: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-top: 28px;
    }
}
